.Present {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.Present > * {
    padding: 1rem;
    width: 100vw;
}

.book {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0;
}

.presentImage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.presentImage > img {
    max-height: calc(var(--vh, 1vh) * 65);
    max-width: 95vw;
    background: #ffffff;
    border: 2px solid #223843;
}

.presentCaption {
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-width: 90vw;
}

.presentControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.presentControl:not(:first-child) {
    margin-left: 1rem;
}

.presentControl {
    width: 2rem;
    height: 2rem;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40%;
    box-shadow: 3px 3px 0 0 #ccc;
}