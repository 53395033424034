@import 'colors';

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 33vh;
}

.grid-button {
    border-radius: 40%;
    width: 10vh;
    height: 10vh;
    border: 0.5vh solid black;
}

.grid-button:active {
    background: black;
}
