$background: #eff1f3;
$accent: black;
$accentLight: hsl(0, 0%, 100%);
$foreground: #333333;
$shadow: 3px 3px 0 0 #ccc;
$danger: #bc1d1c;
$success: #28a745;
$info: hsl(210, 100%, 60%);

//Palette
$palWhite: hsl(0, 0%, 100%);
$palGrey: hsl(0, 0%, 50%);
$palBlack: hsl(0, 0%, 0%);
$palLightRed: hsl(0, 75%, 70%);
$palRed: hsl(0, 75%, 50%);
$palDarkRed: hsl(0, 100%, 30%);
$palLightOrange: hsl(30, 100%, 80%);
$palOrange: hsl(30, 100%, 60%);
$palDarkOrange: hsl(24, 100%, 50%);
$palLightYellow: hsl(60, 90%, 80%);
$palYellow: hsl(60, 100%, 75%);
$palDarkYellow: hsl(48, 100%, 50%);
$palLightGreen: hsl(120, 100%, 95%);
$palGreen: hsl(120, 60%, 50%);
$palDarkGreen: hsl(120, 100%, 20%);
$palLightBlue: hsl(210, 100%, 85%);
$palBlue: hsl(210, 100%, 60%);
$palDarkBlue: hsl(210, 100%, 20%);
$palLightPurple: hsl(270, 100%, 80%);
$palPurple: hsl(270, 100%, 60%);
$palDarkPurple: hsl(270, 100%, 35%);
$palLightPink: hsl(300, 100%, 80%);
$palPink: hsl(300, 95%, 55%);
$palDarkPink: hsl(300, 100%, 40%);
$palLightBrown: hsl(30, 50%, 75%);
$palBrown: hsl(30, 50%, 40%);
$palDarkBrown: hsl(30, 50%, 25%);
