@import 'prefix';
@import 'colors';

//noinspection CssOverwrittenProperties
.popup-container {
  position: absolute;
  display: flex;
  width: 100vw !important;
  height: 100vh; /* Fallback for browsers that do not support custom properties */
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10000;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.popup-container.hidden {
  display: none;
}

.popup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popped-up {
  @include prefix(animation, pop-up 0.5s forwards);
}


.popped-down {
  @include prefix(animation, pop-down 0.5s forwards);
}

.dialog {
  display: flex;
  max-width: 80vw;
  max-height: 90%;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  background: white;
  > * {
    margin: 1rem;
  }
  overflow-y: auto;
  border-radius: 0.75rem;
  box-shadow: $shadow;
}

.dialog.info {
  align-items: flex-start;
  width: 500px;
  @media (max-device-width: 1000px) {
    width: 90vw;
  }
  max-height: 90vh;

  > *:not(.wide) {
    margin: 0.5rem;
  }
}

.question {
  font-size: 1.75rem;
  text-align: center;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top:0;
  left: 0;
  background: rgba(204, 204, 204, 0.6);
}

@keyframes pop-up {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop-down {
  100% {
    transform: scale(0);
  }
}