@import 'colors';

.entryInput {
    box-shadow: 3px 3px 0 0 #ccc;
    line-height: 1.75rem;
    font-size: 1.75rem;
}

.Entry {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Entry > * {
    width: 95vw;
}

.Entry > :not(:first-child) {
    margin-top: 1rem;
}

.logo {
    background: url('./logo.svg');
    width: 30vh;
    height: 30vh;
    background-size: cover;
}


