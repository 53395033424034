.describe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.captionInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.captionInput > * {
    box-shadow: 3px 3px 0 0 #ccc;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.captionInput .captionField {
    flex-grow: 1;
    line-height: 2.5rem;
}

.captionTarget {
    display: block;
    margin: 1rem;
    max-width: 95%;
    border: 2px solid #223843;
    background: white;
    object-fit: contain;
    box-shadow: 3px 3px 0 0 #ccc;
    flex-grow: 5;
}