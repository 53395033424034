.PresentList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  width: 100vw;
}

.PresentList > * {
  padding: 1rem;
  width: 100vw;
}

.books {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80vh;
  flex-grow: 1;
  align-items: center;
}

.books > * {
  width: 50vw;
  margin: 0.25rem;
}